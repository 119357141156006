import { uiActions } from "./ui-slice";
import { prescriptionActions } from "./prescription-slice";
import { convertToFileName } from "../util/stringFormaters";

const API_URL_PRESCRIPTIONS = process.env.REACT_APP_API_URL + "/prescriptions";

export const fetchAllPrescriptions = (selected_patient_id, token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await fetch(API_URL_PRESCRIPTIONS + "?patient_id=" + selected_patient_id, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Could not fetch prescription data!");
      }

      const data = await response.json();

      return data;
    };

    try {
      const all_prescriptions_received = await fetchData();
      dispatch(
        prescriptionActions.setAllPrescriptions(all_prescriptions_received)
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao buscar dados das prescições!",
        })
      );
    }
  };
};

export const sendNewPrescription = (newPrescription, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Cadastrando...",
        message: "Cadastrando nova prescrição!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_PRESCRIPTIONS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newPrescription),
      });

      if (!response.ok) {
        throw new Error("Falha ao cadastrar nova prescrição.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const new_prescriptions_received = await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Success!",
          message: "Prescrição cadastrada com sucesso!",
        })
      );
      dispatch(
        prescriptionActions.addOnePrescription(new_prescriptions_received)
      );
      dispatch(prescriptionActions.needDownload(true));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Falha ao cadastrar nova prescrição.",
        })
      );
    }
  };
};

export const deleteOnePrescription = (prescription, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Excluindo...",
        message: "Prescrição sendo excluída.",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(
        API_URL_PRESCRIPTIONS + "/" + prescription.id,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: "",
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao excluir prescrição.");
      }

      await response.json();

      return;
    };

    try {
      await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Secesso!",
          message: "Prescrição excluída com sucesso!",
        })
      );
      dispatch(prescriptionActions.removeOnePrescription(prescription));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao excluír prescrição.",
        })
      );
    }
  };
};

export const updatePrescription = (prescription, prescription_id, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Atualizando...",
        message: "Atualizando prescrição!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(
        API_URL_PRESCRIPTIONS + "/" + prescription_id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(prescription),
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao atualizar prescrição.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const prescription_data_received = await sendRequest();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Secesso!",
          message: "Prescrição atualizada com sucesso!",
        })
      );
      dispatch(
        prescriptionActions.updateOnePrescription(prescription_data_received)
      );
      dispatch(prescriptionActions.needDownload(true));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao atualizar prescrição.",
        })
      );
    }
  };
};

export const downloadPrescriptionPDF = (prescription, file_name, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Download...",
        message: "Realizando Download de Prescrição",
      })
    );
    const fetchData = async () => {
      const response = await fetch(
        API_URL_PRESCRIPTIONS + "/" + prescription.id + ".pdf",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error("Could not download prescription pdf!");
      }
      const blob = await response.blob();

      // Create a temporary URL object
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${convertToFileName(file_name)}.pdf`); // Set the filename for the downloaded file
      // Set appropriate 'as' value for the resource based on its type
      link.setAttribute("as", "document"); // For PDF files, use 'document' as the 'as' value
      // Programmatically click the link to trigger the download
      link.click();
      // Clean up the temporary URL object
      window.URL.revokeObjectURL(url);

      return response;
    };

    try {
      await fetchData();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Download de Prescrição realizado com sucesso!",
        })
      );
      dispatch(prescriptionActions.needDownload(false));
      dispatch(prescriptionActions.selectPrescription(""));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao fazer download de Prescrição!",
        })
      );
    }
  };
};
