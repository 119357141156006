import PatchForms from "../components/AllergensComponents/PatchTestComponents/Forms/Forms";
import PacthTestSelector from "../components/AllergensComponents/PatchTestComponents/Forms/PacthTestSelector";
import { handleSubmit as patchHandleSubmit } from "../util/patchSubmitHandler";

import PrickForms from "../components/AllergensComponents/PrickTestComponents/Forms/Forms";
import PrickTestSelector from "../components/AllergensComponents/PrickTestComponents/Forms/PrickTestSelector";
import { handleSubmit as prickHandleSubmit } from "../util/prickSubmitHandler";

import Form from "react-bootstrap/Form";

import HeaderInput from "../components/AllergensComponents/PatchTestComponents/header_footer/HeaderInput";
import { allergensActions } from "../store/allergens-slice";
import { useDispatch, useSelector } from "react-redux";
import {
  createPatchForm,
  downloadAllergensPDF,
  downloadPatchPDF,
  fetchAllAllergens,
  updatePatchForm,
} from "../store/allergens-actions";
import SelectPatchForm from "../components/AllergensComponents/PatchTestComponents/Forms/SelectPatchForm";
import { useEffect } from "react";
import SelectPrickForm from "../components/AllergensComponents/PrickTestComponents/Forms/SelectPrickForm";
import { prickActions } from "../store/prick-slice";
import {
  createPrickForm,
  downloadPrickPDF,
  updatePrickForm,
} from "../store/prick-actions";

function Allergens() {
  const dispatch = useDispatch();
  const patch_selector = useSelector((state) => state.allergens.patch_selector);
  const prick_data = useSelector((state) => state.prick.prick_data);
  const prick_form_selected = useSelector(
    (state) => state.prick.prick_form_selected
  );
  const need_fetch = useSelector((state) => state.allergens.need_fetch);
  const token = useSelector((state) => state.login.token);

  const patch_form_selected = useSelector(
    (state) => state.allergens.patch_form_selected
  );
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );

  function formPatchDataHandler(dataToSend) {
    dataToSend.patch_form.patient_id = selected_patient.id;
    if (patch_form_selected.patient_id) {
      if (patch_form_selected.id) {
        dispatch(updatePatchForm(dataToSend, patch_form_selected.id, token));
      } else {
        dispatch(createPatchForm(dataToSend, token));
      }
    }
  }

  function formPrickDataHandler(dataToSend) {
    dataToSend.prick_form.patient_id = selected_patient.id;
    if (prick_form_selected.patient_id) {
      if (prick_form_selected.id) {
        dispatch(updatePrickForm(dataToSend, prick_form_selected.id, token));
      } else {
        dispatch(createPrickForm(dataToSend, token));
      }
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (patch_form_selected.patient_id !== undefined) {
      patchHandleSubmit(event, patch_selector, formPatchDataHandler);
    }
    if (prick_form_selected.patient_id !== undefined) {
      prickHandleSubmit(event, prick_data, formPrickDataHandler);
    }
  }

  useEffect(() => {
      dispatch(fetchAllAllergens(selected_patient.id, token));
  }, [dispatch, need_fetch, token, selected_patient]);

  const patch_need_download = useSelector(
    (state) => state.allergens.need_download
  );
  const prick_need_download = useSelector((state) => state.prick.need_download);

  useEffect(() => {
    if (
      prick_form_selected.id &&
      patch_form_selected.id &&
      patch_need_download &&
      prick_need_download
    ) {
      dispatch(
        downloadAllergensPDF(
          prick_form_selected,
          patch_form_selected,
          selected_patient.name,
          token
        )
      );
    }
    if (
      !prick_form_selected.id &&
      patch_form_selected.id &&
      patch_need_download
    ) {
      dispatch(
        downloadPatchPDF(patch_form_selected, selected_patient.name, token)
      );
    }
    if (
      prick_form_selected.id &&
      !patch_form_selected.id &&
      prick_need_download
    ) {
      dispatch(
        downloadPrickPDF(prick_form_selected, selected_patient.name, token)
      );
    }
  }, [
    dispatch,
    prick_form_selected,
    patch_form_selected,
    patch_need_download,
    prick_need_download,
    selected_patient,
    token,
  ]);

  useEffect(() => {
    dispatch(allergensActions.setPatchMeasurements([]));
    dispatch(allergensActions.deselectAllPatch());
    dispatch(prickActions.deselectAllPrick());
  }, [dispatch, selected_patient]);

  return (
    <div className="container">
      {selected_patient && (
        <Form onSubmit={handleSubmit}>
          <h1 className="mt-5">Teste de Contato - Patch Test</h1>
          <SelectPatchForm />
          {patch_form_selected.patient_id && <PacthTestSelector />}
          {patch_form_selected.patient_id && <HeaderInput />}
          {patch_form_selected.patient_id && <PatchForms />}
          <h1 className="mt-5">Teste de Puntura - Prick Test</h1>
          <SelectPrickForm />
          {prick_form_selected.patient_id && <PrickTestSelector />}
          {prick_form_selected.patient_id && <PrickForms />}
          <button className="btn btn-primary my-3" type="submit">
            Gerar Laudo
          </button>
        </Form>
      )}
    </div>
  );
}
export default Allergens;
