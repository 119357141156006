import { uiActions } from "./ui-slice";
import { patientRecordsActions } from "./patient_record-slice";

const API_URL_PATIENT_RECORDS =
  process.env.REACT_APP_API_URL + "/patient_records";

export const fetchAllPatientRecords = (selected_patient_id, token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await fetch(
        API_URL_PATIENT_RECORDS + "?patient_id=" + selected_patient_id,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) {
        throw new Error("Could not fetch patient records data!");
      }

      const data = await response.json();

      return data;
    };

    try {
      const all_patient_records_received = await fetchData();
      dispatch(
        patientRecordsActions.setAllPatientRecord(all_patient_records_received)
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao buscar dados dos registros do paciente!",
        })
      );
    }
  };
};

export const sendNewPatientRecord = (newPatientRecord, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Cadastrando...",
        message: "Cadastrando novo Registro de Paciente!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_PATIENT_RECORDS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newPatientRecord),
      });

      if (!response.ok) {
        throw new Error("Falha ao cadastrar novo registro de paciente.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const new_patient_record_received = await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Success!",
          message: "Registro de Paciente cadastrado com sucesso!",
        })
      );
      dispatch(
        patientRecordsActions.addOnePatientRecord(new_patient_record_received)
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Falha ao cadastrar novo regitro de paciente.",
        })
      );
    }
  };
};

export const deleteOnePatientRecord = (patient_record, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Excluindo...",
        message: "Registro de paciente sendo excluído.",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(
        API_URL_PATIENT_RECORDS + "/" + patient_record.id,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: "",
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao excluir registro de paciente.");
      }

      await response.json();

      return;
    };

    try {
      await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Secesso!",
          message: "Registro de paciente excluído com sucesso!",
        })
      );
      dispatch(patientRecordsActions.removeOnePatientRecord(patient_record));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao excluír registro de paciente.",
        })
      );
    }
  };
};

export const updatePatientRecord = (
  patient_record,
  patient_record_id,
  token
) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Atualizando...",
        message: "Atualizando registro de paciente!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(
        API_URL_PATIENT_RECORDS + "/" + patient_record_id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(patient_record),
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao atualizar registro de paciente.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const patient_record_data_received = await sendRequest();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Secesso!",
          message: "Registro de paciente atualizado com sucesso!",
        })
      );
      dispatch(
        patientRecordsActions.updateOnePatientRecord(
          patient_record_data_received
        )
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao atualizar registro de  paciente.",
        })
      );
    }
  };
};
