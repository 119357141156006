import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { prescriptionActions } from "../../store/prescription-slice";
import {
  fetchAllPrescriptions,
  downloadPrescriptionPDF,
  deleteOnePrescription,
} from "../../store/prescription-actions";

import { confirm } from "../../util/Confirmation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faFile, faClone } from "@fortawesome/free-solid-svg-icons";
import classes from "./SelectPrescription.module.css";
import { formatIsoDateStringToCustom } from "../../util/stringFormaters";

function SelectPrescription() {
  const dispatch = useDispatch();
  const all_prescriptions = useSelector(
    (state) => state.prescription.all_prescriptions
  );
  const selected_prescription = useSelector(
    (state) => state.prescription.selected_prescription
  );
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );

  const prescription_need_fetch = useSelector(
    (state) => state.prescription.need_fetch
  );

  const need_download = useSelector(
    (state) => state.prescription.need_download
  );
  const token = useSelector((state) => state.login.token);
  const selected_clinic = useSelector((state) => state.clinic.selected_clinic);

  useEffect(() => {
    dispatch(fetchAllPrescriptions(selected_patient.id, token));
  }, [dispatch, prescription_need_fetch, selected_patient, token]);

  useEffect(() => {
    if (need_download !== false) {
      dispatch(
        downloadPrescriptionPDF(
          selected_prescription,
          selected_patient.name,
          token
        )
      );
    }
  }, [dispatch, need_download, selected_patient, selected_prescription, token]);

  useEffect(() => {
    dispatch(prescriptionActions.selectPrescription(""));
  }, [dispatch, selected_patient]);

  const handleDelete = async (one_prescription) => {
    if (await confirm("Tem certeza que deseja excluir esse formulário?")) {
      dispatch(deleteOnePrescription(one_prescription, token));
    }
  };

  function newPrescriptionFormHandler() {
    dispatch(
      prescriptionActions.selectPrescription({
        patient_id: selected_patient.id,
        clinic_id: selected_clinic.id,
        recipe: true,
        oral_use: "",
        external_use: "",
        inhalation_use: "",
        injectable_use: "",
        protocol: "",
        signature_date: new Date().toISOString().split("T")[0],
      })
    );
  }

  function clonePrescriptionFormHandler(one_prescription) {
    // Destructure the `id` out of the prescription to not clone it,
    // then clone the rest of the object while updating `signature_date`.
    const { id, ...clone } = {
      ...one_prescription,
      signature_date: new Date().toISOString().split("T")[0],
    };

    // Dispatch an action to select or update the prescription
    // with the cloned object excluding the original `id`.
    dispatch(prescriptionActions.selectPrescription(clone));
  }

  function cancelPrescriptionFormHandler(e) {
    dispatch(prescriptionActions.selectPrescription(""));
  }

  return (
    <div className={`border rounded container w-75`}>
      <h2>Lista de Prescrições do Paciente:</h2>
      <div className={`list-group ${classes.scroll}`}>
        {all_prescriptions
          .filter(
            (one_prescription) =>
              one_prescription.patient_id === selected_patient.id
          )
          .map((one_prescription, index) => (
            <div
              className={`list-group-item list-group-item-action btn d-flex ${
                selected_prescription.id === one_prescription.id &&
                "active  z-0"
              }`}
              key={index}
            >
              <div
                className="col"
                onClick={() =>
                  dispatch(
                    prescriptionActions.selectPrescription(one_prescription)
                  )
                }
              >
                {"Prescrição criada em "}
                {formatIsoDateStringToCustom(one_prescription.updated_at)}
              </div>
              <FontAwesomeIcon
                icon={faClone}
                title="Clonar Informações"
                onClick={() => clonePrescriptionFormHandler(one_prescription)}
                className="btn"
              />
              {one_prescription.pdf && (
                <FontAwesomeIcon
                  icon={faFile}
                  title="Fazer Download"
                  onClick={() =>
                    dispatch(
                      downloadPrescriptionPDF(
                        one_prescription,
                        selected_patient.name,
                        token
                      )
                    )
                  }
                  className="btn"
                />
              )}
              <FontAwesomeIcon
                icon={faTrashCan}
                title="Excluir"
                onClick={() => handleDelete(one_prescription)}
                className="btn"
              />
            </div>
          ))}
      </div>
      {!selected_prescription.patient_id && (
        <button
          className="btn btn-primary my-3"
          onClick={newPrescriptionFormHandler}
        >
          Criar Nova Prescrição
        </button>
      )}
      {selected_prescription.patient_id && (
        <button
          className="btn btn-primary my-3"
          onClick={cancelPrescriptionFormHandler}
        >
          Cancelar Prescrição
        </button>
      )}
    </div>
  );
}
export default SelectPrescription;
