import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { patientRecordsActions } from "../../store/patient_record-slice";
import {
  fetchAllPatientRecords,
  deleteOnePatientRecord,
} from "../../store/patient_record-actions";

import { confirm } from "../../util/Confirmation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import classes from "./SelectPatientRecord.module.css";
import { formatDatetoBr } from "../../util/stringFormaters";

function SelectPatientRecord() {
  const dispatch = useDispatch();
  const all_patient_records = useSelector(
    (state) => state.patient_record.all_patient_records
  );
  const selected_patient_record = useSelector(
    (state) => state.patient_record.selected_patient_record
  );
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );
  const patient_record_need_fetch = useSelector(
    (state) => state.patient_record.need_fetch
  );
  const token = useSelector((state) => state.login.token);

  useEffect(() => {
    dispatch(fetchAllPatientRecords(selected_patient.id, token));
  }, [dispatch, patient_record_need_fetch, token, selected_patient]);

  useEffect(() => {
    dispatch(patientRecordsActions.selectPatientRecord(""));
  }, [dispatch, selected_patient]);

  const handleDelete = async (one_patient_record) => {
    if (await confirm("Tem certeza que deseja excluir esse formulário?")) {
      dispatch(deleteOnePatientRecord(one_patient_record, token));
    }
  };

  function newPatientRecordFormHandler() {
    dispatch(
      patientRecordsActions.selectPatientRecord({
        patient_id: selected_patient.id,
        record_text: "",
        record_date: new Date().toISOString().split("T")[0],
      })
    );
  }

  return (
    <div className={`border rounded container w-75`}>
      <div className="row">
        <h2 className="col">Lista de Registros do Paciente:</h2>
        <button
          className="col-2 btn btn-primary m-1"
          onClick={newPatientRecordFormHandler}
        >
          Criar Novo Registro
        </button>
      </div>
      <div className={`list-group ${classes.scroll}`}>
        {all_patient_records
          .filter(
            (one_patient_record) =>
              one_patient_record.patient_id === selected_patient.id
          )
          .map((one_patient_record, index) => (
            <div
              className={`list-group-item list-group-item-action btn d-flex ${
                selected_patient_record.id === one_patient_record.id &&
                "active  z-0"
              }`}
              key={index}
            >
              <div
                className="col"
                onClick={() =>
                  dispatch(
                    patientRecordsActions.selectPatientRecord(
                      one_patient_record
                    )
                  )
                }
              >
                {formatDatetoBr(one_patient_record.record_date)}
                {`: `}
                {one_patient_record.record_text}
              </div>
              <FontAwesomeIcon
                icon={faTrashCan}
                title="Excluir"
                onClick={() => handleDelete(one_patient_record)}
                className="btn"
              />
            </div>
          ))}
      </div>
    </div>
  );
}
export default SelectPatientRecord;
