import { uiActions } from "./ui-slice";
import { imunoActions } from "./imuno-slice";
import { convertToFileName } from "../util/stringFormaters";

const API_URL_IMMUNOTHERAPIES =
  process.env.REACT_APP_API_URL + "/immunotherapies";

const API_URL_SUMMARIES = process.env.REACT_APP_API_URL + "/summaries";

export const fetchAllImmunotherapies = (selected_patient_id, token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await fetch(
        API_URL_IMMUNOTHERAPIES + "?patient_id=" + selected_patient_id,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error("Could not fetch imuno data!");
      }

      const data = await response.json();

      return data;
    };

    try {
      const all_immunotherapies_received = await fetchData();
      dispatch(
        imunoActions.setAllImmunotherapies(all_immunotherapies_received)
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao buscar cadastro de Imunoterapia!",
        })
      );
    }
  };
};

export const sendNewImmunotherapy = (newImmunotherapy, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Cadastrando...",
        message: "Cadastrando nova Imunoterapia!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_IMMUNOTHERAPIES, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newImmunotherapy),
      });

      if (!response.ok) {
        throw new Error("Falha ao cadastrar nova Imunoterapia.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const new_immunotherapies_received = await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Imunoterapia cadastrada com sucesso!",
        })
      );
      dispatch(imunoActions.addOneImmunotherapy(new_immunotherapies_received));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Falha ao cadastrar nova Imunoterapia.",
        })
      );
    }
  };
};

export const deleteOneImmunotherapy = (immunotherapy, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Excluindo...",
        message: "Cadastro Excluído!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(
        API_URL_IMMUNOTHERAPIES + "/" + immunotherapy.id,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: "",
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao excluir cadastro de Imunoterapia.");
      }

      await response.json();

      return;
    };

    try {
      await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Imunoterapia excluída com sucesso!",
        })
      );
      dispatch(imunoActions.removeOneImmunotherapy(immunotherapy));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Falha ao excluír cadastro de Imunoterapia.",
        })
      );
    }
  };
};

export const updateImmunotherapy = (immunotherapy, immunotherapy_id, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Atualizando...",
        message: "Atualizando Cadastro do Imunoterapia!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(
        API_URL_IMMUNOTHERAPIES + "/" + immunotherapy_id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(immunotherapy),
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao atualizar cadastro de Imunoterapia.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const immunotherapy_data_received = await sendRequest();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Imunoterapia atualizada com sucesso!",
        })
      );
      dispatch(
        imunoActions.updateOneImmunotherapy(immunotherapy_data_received)
      );
      dispatch(imunoActions.needDownload(true));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao atualizar cadastro de  Imunoterapia.",
        })
      );
    }
  };
};

export const fetchOneSummary = (patient_id, token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await fetch(API_URL_SUMMARIES + "/" + patient_id, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error("Could not fetch prick summary!");
      }

      const data = await response.json();

      return data;
    };

    try {
      const one_prick_summary = await fetchData();
      dispatch(imunoActions.setPrickSummary(one_prick_summary.prick_summary));
      dispatch(imunoActions.setPatchSummary(one_prick_summary.patch_summary));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao Buscar Resumo do Prick!",
        })
      );
    }
  };
};

export const downloadImmunotherapyPDF = (immunotherapy, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Download...",
        message: "Realizando Download de Imunoterapia",
      })
    );
    const fetchData = async () => {
      const response = await fetch(
        API_URL_IMMUNOTHERAPIES + "/" + immunotherapy.id + ".pdf",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error("Could not download immunotherapy pdf!");
      }

      const blob = await response.blob();

      // Create a temporary URL object
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${convertToFileName(immunotherapy.name)}.pdf`
      ); // Set the filename for the downloaded file
      // Set appropriate 'as' value for the resource based on its type
      link.setAttribute("as", "document"); // For PDF files, use 'document' as the 'as' value
      // Programmatically click the link to trigger the download
      link.click();
      // Clean up the temporary URL object
      window.URL.revokeObjectURL(url);

      return response;
    };

    try {
      await fetchData();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Download de imunoterapia realizado com sucesso!",
        })
      );
      dispatch(imunoActions.needDownload(false));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao fazer download de Imunoterapia!",
        })
      );
    }
  };
};
