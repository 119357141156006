import { uiActions } from "./ui-slice";
import { ticActions } from "./tic-slice";
import { convertToFileName } from "../util/stringFormaters";

const API_URL_TICS = process.env.REACT_APP_API_URL + "/tics";

export const fetchAllTics = (selected_patient_id, token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await fetch(API_URL_TICS + "?patient_id=" + selected_patient_id, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Could not fetch tic data!");
      }

      const data = await response.json();

      return data;
    };

    try {
      const all_tics_received = await fetchData();
      dispatch(ticActions.setAllTics(all_tics_received));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao buscar dados das prescições!",
        })
      );
    }
  };
};

export const sendNewTic = (newTic, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Cadastrando...",
        message: "Cadastrando nova tic!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_TICS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newTic),
      });

      if (!response.ok) {
        throw new Error("Falha ao cadastrar nova tic.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const new_tics_received = await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Success!",
          message: "Tic cadastrada com sucesso!",
        })
      );
      dispatch(ticActions.addOneTic(new_tics_received));
      dispatch(ticActions.needDownload(true));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Falha ao cadastrar nova tic.",
        })
      );
    }
  };
};

export const deleteOneTic = (tic, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Excluindo...",
        message: "Tic sendo excluída.",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_TICS + "/" + tic.id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: "",
      });
      if (response.status !== 204) {
        throw new Error("Falha ao excluir tic.");
      }
      
      return;
    };

    try {
      await sendRequest();

      dispatch(ticActions.removeOneTic(tic));
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Secesso!",
          message: "Tic excluída com sucesso!",
        })
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao excluír tic.",
        })
      );
    }
  };
};

export const updateTic = (tic, tic_id, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Atualizando...",
        message: "Atualizando tic!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_TICS + "/" + tic_id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(tic),
      });

      if (!response.ok) {
        throw new Error("Falha ao atualizar tic.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const tic_data_received = await sendRequest();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Secesso!",
          message: "Tic atualizada com sucesso!",
        })
      );
      dispatch(ticActions.updateOneTic(tic_data_received));
      dispatch(ticActions.needDownload(true));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao atualizar tic.",
        })
      );
    }
  };
};

export const downloadTicPDF = (tic, file_name, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Download...",
        message: "Realizando Download de Tic",
      })
    );
    const fetchData = async () => {
      const response = await fetch(API_URL_TICS + "/" + tic.id + ".pdf", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error("Could not download tic pdf!");
      }
      const blob = await response.blob();

      // Create a temporary URL object
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${convertToFileName(file_name)}.pdf`); // Set the filename for the downloaded file
      // Set appropriate 'as' value for the resource based on its type
      link.setAttribute("as", "document"); // For PDF files, use 'document' as the 'as' value
      // Programmatically click the link to trigger the download
      link.click();
      // Clean up the temporary URL object
      window.URL.revokeObjectURL(url);

      return response;
    };

    try {
      await fetchData();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Download de tic realizado com sucesso!",
        })
      );
      dispatch(ticActions.needDownload(false));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao fazer download de Tic!",
        })
      );
    }
  };
};
