import { uiActions } from "./ui-slice";
import { allergensActions } from "./allergens-slice";
import { prickActions } from "./prick-slice";
import { convertToFileName } from "../util/stringFormaters";

const API_URL_PATCH = process.env.REACT_APP_API_URL + "/patchs";
const API_URL_ALLERGENS = process.env.REACT_APP_API_URL + "/allergens";

export const fetchAllAllergens = (selected_patient_id, token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await fetch(API_URL_ALLERGENS + "?patient_id=" + selected_patient_id, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error("Could not fetch allergens data!");
      }

      const data = await response.json();

      return data;
    };

    try {
      const all_allergens_received = await fetchData();
      dispatch(
        allergensActions.setAllPatchs(all_allergens_received.patch_forms)
      );
      dispatch(prickActions.setAllPricks(all_allergens_received.prick_forms));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao buscar cadastro de Allergenos!",
        })
      );
    }
  };
};

export const createPatchForm = (newForm, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Enviando...",
        message: "Enviando Teste de Patch!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_PATCH, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newForm),
      });

      if (!response.ok) {
        throw new Error("Falha ao enviar teste de patch.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const data = await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Teste de patch enviado com sucesso!",
        })
      );

      dispatch(allergensActions.addOnePatchForm(data));
      dispatch(allergensActions.setPatchFormSelected(data));
      dispatch(allergensActions.needDownload(true));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao enviar teste de patch.",
        })
      );
    }
  };
};

export const deleteOnePatchForm = (patch_form, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Excluindo...",
        message: "Cadastro Excluído!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_PATCH + "/" + patch_form.id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: "",
      });

      if (!response.ok) {
        throw new Error("Falha ao excluir cadastro de formulário patch.");
      }

      await response.json();

      return;
    };

    try {
      await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Success!",
          message: "Formulário Patch excluído com sucesso!",
        })
      );
      dispatch(allergensActions.removeOnePatchForm(patch_form));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao excluír cadastro de formulário Patch.",
        })
      );
    }
  };
};

export const fetchOnePatchFormMeasurements = (patch_form_id, token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await fetch(
        API_URL_PATCH + "/" + patch_form_id + ".json",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error("Could not fetch patch form data!");
      }

      const data = await response.json();

      return data;
    };

    try {
      const one_patch_form_measurements = await fetchData();
      dispatch(
        allergensActions.setPatchMeasurements(one_patch_form_measurements)
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao Buscar Formulários Patch!",
        })
      );
    }
  };
};

export const updatePatchForm = (patch_form, patch_form_id, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Enviando...",
        message: "Atualizando Teste de Alérgenos!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_PATCH + "/" + patch_form_id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(patch_form),
      });

      if (!response.ok) {
        throw new Error("Falha ao atualizar teste de alérgenos.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const data = await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Teste de Alérgeno atualizado com sucesso!",
        })
      );
      dispatch(allergensActions.updateOnePatchForm(data));
      dispatch(allergensActions.needDownload(true));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao atualizar teste de alérgeno.",
        })
      );
    }
  };
};

export const downloadPatchPDF = (patch_form, file_name, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Download...",
        message: "Realizando Download de Patch",
      })
    );
    const fetchData = async () => {
      const response = await fetch(
        API_URL_PATCH + "/" + patch_form.id + ".pdf",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error("Could not download patch pdf!");
      }
      const blob = await response.blob();

      // Create a temporary URL object
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${convertToFileName(file_name)}.pdf`); // Set the filename for the downloaded file
      // Set appropriate 'as' value for the resource based on its type
      link.setAttribute("as", "document"); // For PDF files, use 'document' as the 'as' value
      // Programmatically click the link to trigger the download
      link.click();
      // Clean up the temporary URL object
      window.URL.revokeObjectURL(url);

      return response;
    };

    try {
      await fetchData();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Download de patch realizado com sucesso!",
        })
      );
      dispatch(allergensActions.needDownload(false));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao fazer download de Patch!",
        })
      );
    }
  };
};

export const downloadAllergensPDF = (
  prick_form,
  patch_form,
  file_name,
  token
) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Download...",
        message: "Realizando Download de Allergens",
      })
    );
    const fetchData = async () => {
      const response = await fetch(
        API_URL_ALLERGENS +
          ".pdf?prick_form_id=" +
          prick_form.id +
          "&patch_form_id=" +
          patch_form.id,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Could not download allergens pdf!");
      }
      const blob = await response.blob();

      // Create a temporary URL object
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${convertToFileName(file_name)}.pdf`); // Set the filename for the downloaded file
      // Set appropriate 'as' value for the resource based on its type
      link.setAttribute("as", "document"); // For PDF files, use 'document' as the 'as' value
      // Programmatically click the link to trigger the download
      link.click();
      // Clean up the temporary URL object
      window.URL.revokeObjectURL(url);

      return response;
    };

    try {
      await fetchData();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Download de allergens realizado com sucesso!",
        })
      );
      dispatch(prickActions.needDownload(false));
      dispatch(allergensActions.needDownload(false));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao fazer download de Allergens!",
        })
      );
    }
  };
};
