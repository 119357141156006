import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  fetchAllSolicitacoes,
  downloadSolicitacaoPDF,
  deleteOneSolicitacao,
} from "../../../store/solicitacao-actions";

import { formatIsoDateStringToCustom } from "../../../util/stringFormaters";
import { confirm } from "../../../util/Confirmation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faFile } from "@fortawesome/free-solid-svg-icons";
import classes from "./SelectSolicitacao.module.css";

function SelectSolicitacao() {
  const dispatch = useDispatch();
  const all_solicitacoes = useSelector(
    (state) => state.solicitacao.all_solicitacoes
  );
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );
  const solicitacao_need_fetch = useSelector(
    (state) => state.solicitacao.need_fetch
  );
  const token = useSelector((state) => state.login.token);

  useEffect(() => {
    dispatch(fetchAllSolicitacoes(selected_patient.id, token));
  }, [dispatch, solicitacao_need_fetch, token, selected_patient]);

  const handleDelete = async (one_solicitacao) => {
    if (await confirm("Tem certeza que deseja excluir esse formulário?")) {
      dispatch(deleteOneSolicitacao(one_solicitacao, token));
    }
  };

  return (
    <div className={`border rounded container w-75`}>
      <h2>Lista de Solicitações do Paciente:</h2>
      <div className={`list-group ${classes.scroll}`}>
        {all_solicitacoes
          .filter(
            (one_solicitacao) =>
              one_solicitacao.patient_id === selected_patient.id
          )
          .map((one_solicitacao, index) => (
            <div
              className="list-group-item list-group-item-action btn d-flex"
              key={index}
            >
              <div className="col">
                {"Solicitação criada em "}
                {formatIsoDateStringToCustom(one_solicitacao.updated_at)}
              </div>
              <FontAwesomeIcon
                icon={faFile}
                title="Fazer Download"
                onClick={() =>
                  dispatch(
                    downloadSolicitacaoPDF(
                      one_solicitacao,
                      selected_patient.name,
                      token
                    )
                  )
                }
                className="btn"
              />

              <FontAwesomeIcon
                icon={faTrashCan}
                title="Excluir"
                onClick={() => handleDelete(one_solicitacao)}
                className="btn"
              />
            </div>
          ))}
      </div>
    </div>
  );
}
export default SelectSolicitacao;
